import 'datatables.net';
import * as $ from 'jquery';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { Angular2Csv } from 'angular2-csv';
import { Component, OnInit } from '@angular/core'
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SnackbarService } from '../services/snackbar.service';


@Component({
  selector: 'app-all-users',
  templateUrl: './all-users.component.html',
  styleUrls: ['./all-users.component.css']
})
export class AllUsersComponent implements OnInit {

  allUsers=[];
  userCount;
  userId;
  metricData;
  data1;
  sendDataToStatusApi;
  public showLoader: boolean = false;
  apiBaseUrl: string = environment.url;

  name = 'Angular 6';
  

  constructor(
     private http: HttpClient,
     private router: Router,
     private snackbar:SnackbarService) { }
  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.showLoader = true;
    let http = new HttpParams()
    http = http.append('userType',"Client")
    this.http.get(this.apiBaseUrl + 'v1/getAllUsers',{params:http}).subscribe((data: any) => {

      if (data.status == 200) {
        this.showLoader = false;
        this.allUsers = data.data;
        this.metricData = data.metric;

        $(document).ready(function () {
          $('#example').DataTable();
        });
      }
      else {
        this.allUsers = [];
      }
    })
  }

  openAddUserPage = () => {
    this.router.navigateByUrl('/add-user')
  }


  toggleSwitch(value, id) {
    if (value == "1") {
      this.sendDataToStatusApi = { "status": '0', "userId": id }
    } else if (value == "0") {
      this.sendDataToStatusApi = { "status": '1', "userId": id }
    }
    this.http.post(this.apiBaseUrl + 'v1/acticeInactiveUser', this.sendDataToStatusApi).subscribe((data: any) => {})
  }

  deleteUser(index) {
    if (confirm("Are you sure to want to delete?")) {
      this.userId = { "userId": this.allUsers[index]._id };
      
      this.http.post(this.apiBaseUrl + 'v1/deleteUser', this.userId).subscribe((data: any) => {
        if (data.status == 200) {
          const dataTable = $('#example').DataTable();
          dataTable.row(`:eq(${index})`).remove()
          this.allUsers.splice(index ,1)
          dataTable.draw(false);
          this.snackbar.successSnackBar("User successfully deleted",'');
        }
      });
    }
  }

  formula: string = "Formula 1";
  downloadCSV() {
    var data = this.allUsers.map(user => ({
        ...user,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        age: user.age,
        weight: user.weight,
        height: user.height,
        bmi: user.bmi,
        bloodPressureMax: user.bloodPressureMax,
        bloodPressureMin: user.bloodPressureMin,
        cholesterol: user.cholesterol,
        glucose: user.glucose,
        loginType: user.loginType,
        isActive: user.isActive
      })
    )

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      headers: ['FIRSTNAME', 'LASTNAME', 'EMAIL', 'AGE', 'WEIGHT', 'HEIGHT', 'BMI', 'BP MAX', 'BP MIN', 'CHALESTROL', 'GULCOSE', 'LOGIN TYPE', 'ACTIVE/INACTIVE'],
      showTitle: true,
      useBom: true,
      removeNewLines: false,
      keys: ['firstName', 'lastName', 'email'],
      title: "Users List"
    };

    new Angular2Csv(data, this.formula, options);
  }
}


