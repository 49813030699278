<div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="card card-border">
            <div class="card-body" id="example_wrapper">
                <table id="example" *ngIf="contacts" datatable class="display">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Company</th>
                            <th>Country</th>
                            <th>Service</th>
                            <th>Message</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let contact of contacts, let i=index">
                            <td>{{ i+1 }}</td>
                            <!-- <td>{{ contact.createdAt | date:'yyyy-MM-dd'}}</td> -->
                            <td>{{ contact.createdAt | date:'yyyy-MM-dd HH:mm:ss' }}</td>
                            <td>{{ contact.name }}</td>
                            <td>{{ contact.email }}</td>
                            <td>{{ contact.phone }}</td>
                            <td>{{ contact.company }}</td>
                            <td>{{ contact.country }}</td>
                            <td>{{ services(contact.service) }}</td>
                            <td>
                               <p class="description-data"> {{ contact.message }}</p>
                               <a *ngIf="contact.message.length > 20" (click)="showContent(contact.message)" class="view-data">View more</a>
                            </td>
                        </tr>
                        <tr *ngIf="contacts.length==0">
                            <td colspan="100%">No data found!</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>