import 'datatables.net';
import * as $ from 'jquery';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SnackbarService } from '../services/snackbar.service';
import { NgxBootstrapConfirmService } from 'ngx-bootstrap-confirm';


@Component({
  selector: 'app-workout-challenges',
  templateUrl: './workout-challenges.component.html',
  styleUrls: ['./workout-challenges.component.css']
})
export class WorkoutChallengesComponent implements OnInit {

  allChallenges;
  public showLoader: boolean = false;
  apiBaseUrl: string = environment.url;

  constructor(private http: HttpClient,
     private router: Router,
    private ngxBootstrapConfirmService: NgxBootstrapConfirmService,
    private snackbar: SnackbarService) { }

  ngOnInit() {

    this.adminLogin();
    $(document).ready(function () {
      $('#example').DataTable();
    });
  }

  adminLogin() {
    this.showLoader = true;
    this.http.get(this.apiBaseUrl + 'v1/getAllWorkoutChallenges').subscribe((data: any) => {

      if (data.status == 200) {
        this.showLoader = false;
        this.allChallenges = data.data;
        $(document).ready(function () {
          $('#example').DataTable();
        });
      }
      else {
        this.allChallenges = '';
      }
    })
  }

  showContent(content) {
    this.snackbar.show(content,'')
  }

  openAddChallengePage = () => {

    this.router.navigateByUrl('/add-workout-challenge')
  }


  deleteChallenge(id: string, index: number) {
    let options = {
      title: 'Are you sure you want to remove the challenge?',
      confirmLabel: 'Yes',
      declineLabel: 'No'
    }
    this.ngxBootstrapConfirmService.confirm(options).then((res: boolean) => {
      if (res) {
        this.http.post(this.apiBaseUrl + 'v1/deleteChallengeAdmin', { challengeId: id }).subscribe((data: any) => {
          this.allChallenges.splice(index - this.allChallenges.length, 1);
          this.allChallenges.splice(index, 1);

          this.snackbar.successSnackBar(" Challenge  deleted successfully.", '');
          this.adminLogin()
        })

      }
    }
    );
  }
}

