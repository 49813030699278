import { Routes } from 'src/app/models/routes';
import { Component, OnInit } from '@angular/core';
import { ReportService } from '../services/report.service';
import { environment } from 'src/environments/environment';
import { SnackbarService } from '../services/snackbar.service';

@Component({
  selector: 'app-report-management',
  templateUrl: './report-management.component.html',
  styleUrls: ['./report-management.component.css']
})
export class ReportManagementComponent implements OnInit {

  reports : any[] = []

  route : Routes = new Routes();
  SERVER_URL: string = environment.url;
  WEB_URL: string = environment.web;

  constructor(
    private snackbarService:SnackbarService,
    private reportService : ReportService) { }


  ngOnInit(): void {
    this.getAllReport();

  }

  showContent(content) {
    this.snackbarService.show(content,'')
  }

  getAllReport(){
    this.reportService.getReports().subscribe((resp : any)=>{
      if(resp.status ==200){
           this.reports = resp.data.filter(data => data.elearning || data.post_id);
           $(document).ready(function() {
            $('#example').DataTable();
            });
      }
    })
  }

  getReportType(data:any) {
    if(data.elearning) return 'E-Learning Video'
    else if(data.post_id?.user?.userType === 'Professional') return 'Med Post'
    else if(data.post_id?.user?.userType === 'Client') return 'Me Post'
    else return 'NA'
  }

}
