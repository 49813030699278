<section class="view_sec">
    <div class="container card">
      <div class="row">
        <div class="col-lg-6 col-md-12  mb-2" style="margin-top: 5%;">
            <div class="swiper-container mySwiper">
                <swiper [config]="config">
                <div class="swiper-wrapper">
                  <div class="swiper-slide" *ngFor="let image of product.product.images">
                    <img [src]="apiBaseUrl+image.image" style="height: 400px;width: 100%;object-fit: contain;">
                  </div>
                </div>
                <div class="swiper-pagination"></div>
                </swiper>
              </div>
        </div>
        <div class="details col-lg-6 col-md-12 px-1" style="margin-top: 5%;">


          <h3 class="product-title"><b>{{product.product?.name}}</b></h3>
          <p class="product-description">{{product.product?.description}}</p>
          <h4 class="price mt-3"><b>Order Id: </b><span>{{product._id}}</span></h4>       

          <h4 class="price mt-3"><b>Category: </b><span>{{product.product?.category?.name}}</span></h4>
          <h4 class="price mt-3"><b>Product Price: </b><span>${{product.price}}</span></h4>
          <h4 class="price mt-3"><b>Quantity: </b><span>{{product.qty}}</span></h4>
          <h4 class="price mt-3"><b>Shipping Charges: </b><span>${{product.shipping_charge}}</span></h4>
          <h4 class="price mt-3"><b>Taxation: </b>
              <p *ngFor="let tax of product.tax">
                {{ tax.tax_rate_details?.percentage_decimal | number:'1.2-2' }}% 
                {{ tax.tax_rate_details?.tax_type | uppercase }} {{ tax.inclusive ? '(Inclusive)' : '(Exclusive)' }}
                = ${{ tax.amount/100  | number:'1.2-2' }}
              </p>
          </h4>
          
          <h4 class="price mt-3"><b>Paid Amount: </b><span>${{product.grandtotal  | number:'1.2-2' }}</span></h4>


          <h4 class="price mt-3"><b>Ordered On: </b><span>{{product.createdAt  | date :'LLLL dd, yyyy , h:mm a'}}</span></h4>     
          <h4 class="price mt-3" ><label *ngIf="(product.status != 'ACT') && (product.status != 'CAN' )"><b>Postage label: </b>&nbsp; <a href="{{product.postage_label}}" target="_blank"> {{product.postage_label}}</a></label></h4>       
          <h4 class="price mt-3">
            <label *ngIf="(product.status != 'ACT') && (product.status != 'CAN' ) ">
              <b>Tracking Link:  </b>&nbsp; 
              <a [href]="product?.tracker?.public_url" target="_blank">{{ product?.tracker?.public_url }}</a>
            </label>
          </h4>       
          <hr>
          <div>
            <span ><h2 style="margin-left: 33%;">Address</h2></span>
          
            <h4 class="price mt-3"><b>Name: </b><span>{{product.address.name}}</span></h4>
            <h4 class="price mt-3"><b>Phone: </b><span>{{product.address.phone}}</span></h4>
            <h4 class="price mt-3"><b>Address: </b><span>{{product.address.street1+" "+product.address.street2+", "+product.address.city+", "+product.address.state+", "+product.address.country }}</span></h4>
            <h4 class="price mt-3"><b>Zip Code: </b><span>{{product.address.zip_code}}</span></h4>
            

          </div>
          <hr>
          <h4 class="price mt-3"><label *ngIf="product.cancel_reason != '' "><b>Cancel Reason:  </b> {{product?.cancel_reason}}</label> </h4>       
          <h4 class="price mt-3"><label *ngIf="product.returnReason != '' "><b>Return Reason:  </b> {{product?.returnReason}}</label> </h4>       



<!-- <hr> -->
<!-- <div>
  <span ><h2 style="margin-left: 33%;">User Details</h2></span>
 
      <h4 class="price mt-3"><b>Name: </b><span>{{product.address.name}}</span></h4>
      <h4 class="price mt-3"><b>Phone: </b><span>{{product.address.phone}}</span></h4>
      <h4 class="price mt-3"><b>City: </b><span>{{product.address.city}}</span></h4>
      <h4 class="price mt-3"><b>State: </b><span>{{product.address.state}}</span></h4>
      <h4 class="price mt-3"><b>Address: </b><span>{{product.address.address}}</span></h4>

      <h4 class="price mt-3"><b>Street1: </b><span>{{product.address.street1}}</span></h4>
      <h4 class="price mt-3"><b>Street2: </b><span>{{product.address.street2}}</span></h4>
      <h4 class="price mt-3"><b>Zip_code: </b><span>{{product.address.Zip_code}}</span></h4>

</div> -->

<span *ngIf="refunded==false && returnRequested==true">
  <button type="submit" class="btn btn-submit mr-3" routerLink="/refund/{{product._id}}" style="margin-bottom: 5%;">Refund</button>
</span>

        </div>

      </div>
    </div>
    </section>