import { Component, OnInit } from '@angular/core';
import { Pagination } from 'src/app/shared/pagination';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { PostService } from 'src/app/services/post.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-world-posts',
  templateUrl: './world-posts.component.html',
  styleUrls: ['./world-posts.component.css']
})
export class WorldPostsComponent implements OnInit {

  posts: []
  webUrl = environment.web
  urlAppend = 'big-me-details'
  description: any
  constructor(private postService: PostService,
    private router: Router,
    private route: ActivatedRoute,
    private snackBarService: SnackbarService) { }

  ngOnInit(): void {
    this.getWorldPosts()
  }

  getWorldPosts() {
    this.postService.getWorldPosts().subscribe((response: any) => {
      this.posts = response.data
      $(document).ready(function () {
        $('#example').DataTable();
      });
    })
  }

  enable(post: any, status: "DEL" | "ACT") {
    this.snackBarService.alertWithConfirmations(`Are you sure you want to ${post.status == 'ACT' ? 'disable' : 'enable'} it?`).then((result) => {
      if (result.isConfirmed) {
        this.postService.enableWebinar(post._id, status).subscribe((response: any) => {
          post.status = status;
        })
      }
    })
  }

  showDescription(description) {
    this.description = description
  }

  delete(index:number) {
    this.snackBarService.alertWithConfirmations("Are you sure you want to delete this webinar?").then(response => {
      if (response.isConfirmed) {
        const post = this.posts[index] as any
        this.postService.deleteWebinar(post._id).subscribe(() => {
          const dataTable = $('#example').DataTable();
          dataTable.row(`:eq(${index})`).remove()
          this.posts.splice(index ,1)
          dataTable.draw(false);
          this.snackBarService.successSnackBar("Webinar deleted successfully", null)
        })
      }
    })
  }
}
