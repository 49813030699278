


  <div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
     <div class="card card-border">
      <div class="card-body" id = "example_wrapper">
          <table id="example" datatable class="display table table-bordered">
              <thead>
                  <tr>
                      <th>Sr. No.</th>
                      <th>Type</th>
                      <th>Date</th>
                      <th>Reported By</th>
                      <th>Reason</th>
                      <th>View</th>
                  </tr>
              </thead>
             <tbody>
              <tr *ngFor="let data of reports; let i = index">
                  <td>{{i+1}}</td>

                  <td>
                    {{ getReportType(data) }}
                  </td>
                  <td>
                    {{ data.createdAt | date:'yyyy-MM-dd hh:mm a' }}
                  </td>
                  <td>
                    {{ data.user ? data.user.firstName + ' ' + data.user.lastName :
                      (data.post_id ? (data.post_id.user ? (data?.post_id?.user?.name) : 'N/A') : 'N/A') }}
                  </td>

                  <td>
                    {{ data?.reason.substr(0,49) }}
                    <a *ngIf="data.reason && data.reason.length > 50" (click)="showContent(data.reason)" class="view-data">View more</a>
                  </td>

                  <td *ngIf="data.elearning">
                    <a [href]="data.elearning?.url" target="_blank">
                      <span class="fa fa-eye"></span>
                    </a>
                  </td>
                  
                  <td *ngIf="data.post_id && data.post_id.user">
                      <a [href]="(data.post_id.user.userType === 'Professional') ? WEB_URL+'big-med-details?id=' + data.post_id.id :
                                  ((data.post_id.user.userType === 'Client') ? WEB_URL+'big-me-details?id=' + data.post_id.id : '')"
                         target="_blank">
                        <span class="fa fa-eye"></span>
                      </a>
                  </td>
              </tr>

             </tbody>
            </table>

      </div>
     </div>
    </div>
  </div>

