<div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="card card-border">
            <div class="card-body" id="example_wrapper">
                <table id="example" class="display table table-bordered">
                    <thead>
                        <tr>
                            <th>Sr. No.</th>
                            <th>Date</th>
                            <th>Notification Title</th>
                            <th>Message</th>
                            <!-- <th>Action</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let notification of notifications | paginate: { itemsPerPage: pagination.size, currentPage: pagination.page, totalItems:pagination.total}, let i=index">
                            <td>{{(pagination.page*pagination.size)+(i+1)}}</td>
                            <td>{{notification.created_on | date}}</td>
                            <td>{{notification.title}}</td>
                            <td class="med-description-clamp">
                                <p class="description-data"> {{ notification.msg.substring(0, 100) }}</p>
                                <a *ngIf="notification.msg.length > 100" (click)="showContent(notification.msg)"
                                    class="view-data">View more</a>
                            </td>
                            <!-- <td><button class="btn btn-info mr-2" (click)="viewNotification(notification.notificationType)" matTooltip="View" ><i class="fa fa-eye" aria-hidden="true"></i></button></td> -->
                        </tr>
                        <tr *ngIf="notifications.length==0">
                            <td colspan="100%">No data found!</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <pagination-controls *ngIf="notifications && notifications.length > 0" (pageChange)="onPageChange($event)"></pagination-controls>
    </div>
</div>