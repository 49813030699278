import * as $ from 'jquery';
import { Routes } from 'src/app/models/routes';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ELearningService } from 'src/app/services/eLearning/e-learning.service';
import { CategoryDialogComponent } from '../category-dialog/category-dialog.component';


@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {

  showLoader : boolean = true;

  categories : any[] = [];

  route : Routes = new Routes();

  constructor(private _eLearningService : ELearningService, 
              public dialog: MatDialog,
              private snackbar:SnackbarService) { }

  ngOnInit(): void {
     this.getAllCategories();
  }

  addCategory(category : any){
    const dialogRef = this.dialog.open(CategoryDialogComponent, {
      width: '350px',
      data : category,
      position : { top : '50px'}
    });

    dialogRef.afterClosed().subscribe(result => {
       if(result==='result'){
          this.getAllCategories();
       }
     
    });
       
  }


  updateCategory(category : any){
    this.openDialog({ id : category._id, name : category.name});
 }

 openDialog(data : any){
  const dialogRef = this.dialog.open(CategoryDialogComponent, {
    width: '350px',
    data : data,
    position : { top : '50px'}
  });

  dialogRef.afterClosed().subscribe(result => {
     if(result==='result'){
        this.getAllCategories();
     }
     dialogRef.close()
   
  });
}

  getAllCategories(){
    this._eLearningService.getCategories().subscribe((response : any) => {
      this.showLoader = false;
     // console.log(response);
      this.categories = response.data;
    $(document).ready(function() {
      $('#example').DataTable();
      } );
  },error => this.showLoader = false);

}
  deleteCategory(categoryId : string){
    const confirm = window.confirm("Are you sure want to delete this category?");
    if(confirm) {
      this._eLearningService.deleteCategoryById(categoryId).subscribe(() =>{
            var index = this.categories.findIndex(quiz => {
                 quiz._id === categoryId
            });

            if(index!=-1) {
              this.categories.splice(index,1);
              this.snackbar.successSnackBar("Category successfully deleted",'');
            }

      });
    }
  }
}