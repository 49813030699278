import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { shareReplay } from 'rxjs/operators';
import { Menu } from './models/menu';
import { Routes } from './models/routes';
import { AuthService } from './services/auth/auth.service';
import { NotificationService } from './services/notification.service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Big4Health';

  gettingNotifications:boolean=false

  @ViewChild("drawer")
  drawer: MatSidenav;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map((result: any) => result.matches),
      shareReplay()
    );

  routes = new Routes();
  menus: Menu[] = [
    { name: 'Dashboard', route: this.routes.dashboard, subMenu: [] },
    {
      name: 'Users', route: null, subMenu: [
        { name: 'Healthcare Provider', route: this.routes.professional_accounts, subMenu: [] },
        { name: 'Patients', route: this.routes.allUsers, subMenu: [] },
      ]
    },
    {
      name: 'CRM', route: null, subMenu: [
        { name: 'Home', route: this.routes.home, subMenu: [] },
        { name: 'Health Data', route: this.routes.health_data, subMenu: [] },
        { name: 'Certification Logo', route: this.routes.certification_logo, subMenu: [] },
        { name: 'Trusted Logo', route: this.routes.trusted_logo, subMenu: [] },
        { name: 'Testimonials', route: this.routes.testimonials, subMenu: [] },
      ]
    },

    {
      name: 'Post Management', route: null, subMenu: [
        { name: 'BIG4 Health Med', route: this.routes.med_posts, subMenu: [] },
        { name: 'BIG4 Health Me', route: this.routes.me_posts, subMenu: [] },
        { name: 'BIG4 Health World', route: this.routes.world_posts, subMenu: [] },
        { name: 'Flagged Comments', route: this.routes.flagged_comments, subMenu: [] },
      ]
    },
    {
      name: 'Find Healthcare Provider', route: null, subMenu: [
        { name: 'Upcoming appointments', route: this.routes.md_upcoming, subMenu: [] },
        { name: 'Completed appointments', route: this.routes.md_completed, subMenu: [] },
        { name: 'Cancel appointments', route: this.routes.md_cancelled, subMenu: [] },
      ]
    },
    { name: 'Contact Us', route: this.routes.contact_us, subMenu: [] },

    { name: 'Stress Management', route: this.routes.stressManagement, subMenu: [] },
    { name: 'Wellness Challenges', route: this.routes.workoutChallenges, subMenu: [] },
    { name: 'Medication', route: this.routes.medication, subMenu: [] },
    {
      name: 'Quiz', route: null, subMenu: [
        { name: 'Quiz', route: this.routes.quiz, subMenu: [] },
        // { name: 'Rewards', route: this.routes.quiz_rewards, subMenu: [] }
      ]
    },
    {
      name: 'Exercise', route: null, subMenu: [
        { name: 'Exercises', route: this.routes.exercises, subMenu: [] },
        { name: 'Exercise Video', route: this.routes.exerciseVideos, subMenu: [] },
      ]
    },
    {
      name: 'E-Learning', route: null, subMenu: [
        { name: 'Categories', route: this.routes.eLearningCategories, subMenu: [] },
        { name: 'Videos', route: this.routes.eLearningAllVideos, subMenu: [] }
      ]
    },
    {
      name: 'Shop & Product', route: null, subMenu: [
        { name: 'Offers', route: this.routes.offers, subMenu: [] },
        { name: 'Categories', route: this.routes.shopCategories, subMenu: [] },
        { name: 'Add/View Product', route: this.routes.addProduct, subMenu: [] }
      ]
    },
    {
      name: 'Orders', route: null, subMenu: [
        { name: 'Active', route: this.routes.activeOrders, subMenu: [] },
        { name: 'Completed', route: this.routes.pastOrders, subMenu: [] },
        { name: 'Cancel', route: this.routes.cancelOrders, subMenu: [] },
        { name: 'Return', route: this.routes.returnOrders, subMenu: [] }
      ]
    },
    { name: 'Premium User', route: this.routes.premium, subMenu: [] },
    { name: 'Feedback', route: this.routes.feedback, subMenu: [] },
    {
      name: 'Blog', route: null, subMenu: [
        { name: 'Add Blog', route: this.routes.createBlog, subMenu: [] },
        { name: 'Blogs', route: this.routes.blogs, subMenu: [] },
        //{ name : 'Report', route : this.routes.Report, subMenu : [] },
      ]
    },
    { name: 'Reported Posts', route: this.routes.report, subMenu: [] },
    //{ name : 'Banner', route : this.routes.banner, subMenu : [] },

    { name: 'Transaction', route: this.routes.transaction, subMenu: [] },
    { name: 'Other Charges', route: this.routes.charges, subMenu: [] },
    { name: 'Address', route: this.routes.address, subMenu: [] },
    {
      name: 'Mass Emails', route: null, subMenu: [
        { name: 'Send Email', route: this.routes.Emails, subMenu: [] },
        { name: 'Emails sent', route: this.routes.viewSentMail, subMenu: [] }
      ]
    },
    { name: 'Subscriptions', route: this.routes.subscription, subMenu: [] }
  ];

  isLoggedIn$: Observable<boolean>;
  menuName: string = undefined;

  constructor(private breakpointObserver: BreakpointObserver, private _router: Router, private _authService: AuthService
    ,private notificationService:NotificationService) {
    this.isLoggedIn$ = this._authService.loggedIn$;
  }


  ngOnInit(): void {
    setInterval(() =>{
      this.getNotificationCount()
    },30000)
  }

  count:any

  getNotificationCount(){
    this.gettingNotifications=true
    this.notificationService.getCount().subscribe((response :any) =>{
      this.count = response.data
      this.gettingNotifications=false
    })
  }

  logout() {
    this._authService.showNavBar(false);
    sessionStorage.clear();
    localStorage.clear();
    this._router.navigate([''])
  }

  toggleDrawer() {
    if (this.drawer !== null && this.drawer !== undefined) {
      this.drawer.toggle();
    }
  }

  route(value: string) {
    this._router.navigate([value]);
  }

  setMenuName(menuName: string) {
    this.menuName = menuName;
  }

}





